import React from "react";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";
//img
import logoComision from "../../images/cm/LogoComision.png";
import logoColegio from "../../images/cm/LogoColegio.png";

// context
import { Container, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { auth, db } from "../../services/firebase";
// import { useRef } from "react";

function HomeCertificado() {
  // global
  var classes = useStyles();
  // const { userState } = useUser();
  // const pdfViewerRef = useRef();
  const { id } = useParams();

  const [datosClientes, setDatosClientes] = React.useState([]);
  const fechtFirebase = async (ruta) => {
    try {
      await auth.signInWithEmailAndPassword(
        "julio.wu@automatadg.com",
        "juliowu"
      );

      const snapshot = await db
        .ref(`congreso_medicina/${ruta}`)
        .child(id)
        .once("value");
      const data = snapshot.val();
      return data;
    } catch (error) {
      console.error("Error en fechtFirebase:", error.message);
      throw error;
    }
  };

  useEffect(() => {
    fechtFirebase("medicos").then((result) => {
      if (result != null) {
        setDatosClientes(result);
      }
    });
    fechtFirebase("estudiantes").then((result) => {
      if (result != null) {
        setDatosClientes(result);
      }
    });
  }, []);

  return (
    <>
      <Container maxWidth="sm">
        <Grid container spacing={4}>
          <Grid item xs={12} />

          <Grid
            container
            alignItems={"center"}
            direction={"row"}
            className={classes.centered}
          >
            {datosClientes.length !== 0 ? (
              <>
                <Grid container direction={"row"}>
                  <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                  <Grid item xs={3} sx={{ pb: 10 }}>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={logoComision}
                      style={{
                        marginTop: "5vh",
                        maxWidth: "90%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} sx={{ pb: 10 }}>
                    <img
                      className={classes.img}
                      alt="complex"
                      src={logoColegio}
                      style={{ marginTop: "5vh", maxWidth: "90%" }}
                    />
                  </Grid>
                  <Grid item xs={3} sx={{ pb: 10 }}></Grid>
                </Grid>
                <Grid container direction={"row"}>
                  <Grid item xs={12} style={{ margin: "2vh" }}>
                    <Typography
                      align={"center"}
                      variant={"h4"}
                      className={classes.textotitulo}
                      color={"primary"}
                      style={{ color: "#585756", marginTop: "1vh" }}
                    >
                      LXV CONGRESO NACIONAL DE MEDICINA
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align={"center"}>
                      IX CONGRESO DE MÉDICOS ESCRITORES
                    </Typography>
                  </Grid>
                </Grid>

                {datosClientes?.profesion || datosClientes?.colegiado ? (
                  <>
                    <Grid container direction={"row"} style={{ margin: 20 }}>
                      <Grid item xs={12}>
                        <Typography align={"justify"}>
                          La Junta Directiva del Colegio de Médicos y Cirujanos
                          de Guatemala por medio del Comité Organizador hace
                          constar que el{" "}
                          {datosClientes?.profesion === "Médico" || !datosClientes?.profesion? (
                            <>Dr. (a)</>
                          ) : (
                            <>Lic. (a)</>
                          )}
                          :
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"} style={{ marginTop: 10 }}>
                      <Grid item xs={12}>
                        <Typography align={"center"} variant={"h4"}>
                          {datosClientes?.nombre}
                        </Typography>
                        <Typography align={"center"} variant={"h4"}>
                          Colegiado No. {datosClientes?.colegiado}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"} style={{ margin: 20 }}>
                      <Grid item xs={12}>
                        <Typography align={"justify"}>
                          Participó en el LXV Congreso Nacional de Medicina y
                          obtuvo 33 horas crédito las cuales se certifican por
                          medio del diploma de participación digital descargado
                          de esta plataforma y el cual contiene el código QR que
                          permite ingresar a la base de datos de los
                          participantes registrados en el presente sistema
                          informático.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container direction={"row"} style={{ margin: 20 }}>
                      <Grid item xs={12}>
                        <Typography align={"justify"}>
                          La Junta Directiva del Colegio de Médicos y Cirujanos
                          de Guatemala por medio del Comité Organizador hace
                          constar que el (la) estudiante:
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"} style={{ marginTop: 10 }}>
                      <Grid item xs={12}>
                        <Typography align={"center"} variant={"h4"}>
                          {datosClientes?.nombre}
                        </Typography>
                        <Typography align={"center"} variant={"h4"}>
                          Carné No.{datosClientes?.carnet}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container direction={"row"} style={{ margin: 20 }}>
                      <Grid item xs={12}>
                        <Typography align={"justify"}>
                          Participó en el LXV Congreso Nacional de Medicina y se
                          certifica por medio del diploma de participación
                          digital descargado de esta plataforma y el cual
                          contiene el código QR que permite ingresar a la base
                          de datos de los participantes registrados en el
                          presente sistema informático.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Grid container direction={"row"}>
                <Grid item xs={12}>
                  <Typography
                    align={"center"}
                    variant={"h1"}
                    className={classes.textotitulo}
                    style={{ color: "red" }}
                  >
                    ERROR 404
                  </Typography>
                  <Typography
                    align={"center"}
                    variant={"h5"}
                    style={{ color: "red" }}
                  >
                    Usuario no encontrado
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} />
        </Grid>
      </Container>

      {/* <Grid container direction={"row"}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: "2vh" }}>
          <embed
            ref={pdfViewerRef}
            width="100%"
            height="600"
            type="application/pdf"
          />
        </Grid>
      </Grid> */}
    </>
  );
}

export default withRouter(HomeCertificado);
