import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import HomeCertificado from "../pages/certificado/Home";


export default function App() {
  return (
    <HashRouter>
      <Switch>

        <Route path="/:id" component={HomeCertificado} />

        <Redirect to="/" />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

}
