import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";

/*
    databaseURL: "https://latam-eventos.firebaseio.com",
      databaseURL: "http://localhost:9000/?ns=latam-eventos",
*/
const config = {
  apiKey: "AIzaSyDX28708b3xtA602kmEyAb4ECoWsBMZcYQ",
  authDomain: "latam-eventos.firebaseapp.com",
  databaseURL: "https://latam-eventos.firebaseio.com",
  projectId: "latam-eventos",
  storageBucket: "latam-eventos.appspot.com",
  messagingSenderId: "703692565667",
  appId: "1:703692565667:web:89376f92a111363b692316",
  measurementId: "G-8LGQY3PS2J",
};

firebase.initializeApp(config);

export const funciones_fb = firebase.functions();
// funciones_fb.useFunctionsEmulator("http://localhost:5001");
//funciones_fb.useFunctionsEmulator("http://localhost:5001");

export const auth = firebase.auth();
export const db = firebase.database();
export const authPersistencia = firebase.auth.Auth.Persistence.LOCAL;
export const storage = firebase.storage();
export const fecha_hora = firebase;
export const analitics = firebase.analytics();

/*
db.settings({
    timestampsInSnapshots:true,
})

firebase.settings({ 
    timestampsInSnapshots: true 
}); */
